import React, { useEffect, useState } from 'react';
import { composedComponent } from 'utils/functions';
import {
  withStyles, useTheme, Grid, Typography,
} from '@material-ui/core';
import { appActions } from 'commons/reducer';
import { useHistory } from 'react-router-dom';
import styles from './styles';
import saga from './saga';
import BuyYourBikeForm from './components/buyYourBikeForm';
import { buyYourBikeActions } from './reducer';
import BikeCard from './components/BikeCard';

const BuyYourBike = (props) => {
  const { classes, user, purchasableBikes } = props;
  const { fetchPurchasableBikes, sendSimulation } = props.actions;
  const history = useHistory();
  const theme = useTheme();

  useEffect(() => { fetchPurchasableBikes(); }, []);

  return (
    <>
      <BuyYourBikeForm
        className={classes}
        profileComplete={user?.user?.dataComplete}
      />
      <Grid container direction="column" alignItems="center" justifyContent="center">
        {
          !purchasableBikes.length
            ? <Grid style={{ margin: '2rem' }}><Typography variant="h4">Tus bikes no cumplen con las condiciones.</Typography></Grid>
            : purchasableBikes.map((transfer) => (
              <Grid item key={transfer.id} style={{ width: '80%' }}>
                <BikeCard
                  transfer={transfer}
                  bikeData={{
                    categoryName: transfer?.bike?.category?.name, brandName: transfer?.bike?.brand?.name, modelName: transfer?.bike?.model?.name, bikeYear: transfer?.bike?.year, sizeName: transfer?.bike?.size?.name, rimName: transfer?.bike?.rim?.name,
                  }}
                  sendSimulationEmail={sendSimulation}
                  history={history}
                  user={user?.user}
                />
              </Grid>
            ))
        }
      </Grid>
    </>
  );
};

export default composedComponent(BuyYourBike, saga, {
  saga: 'sagaBuyYourBike',
  middlewares: [withStyles(styles)],
  states: [
    'buyYourBike.purchasableBikes',
    'app.objects',
    'app.order',
    'app.user',
  ],
  actions: [buyYourBikeActions, appActions],
});
